<template>
  <div id="app">
      <div class="pages">
        <div class="header-contents">
          <Header></Header>
        </div>
        <div class="pages-contents">
          <router-view></router-view>
          <Footer></Footer>
        </div>
      </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>

</style>
