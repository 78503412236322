<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <h4 >{{ selectedSongs.title }}</h4>
                            </div>
                            
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'albumHatirlarmisin',
        data() {
          return {
            videoData: [
              {
                title: 'Hatırlar mısın',
                url: '9E-E--KgAZs'
              },
              {
                title: 'Ne Olursun',
                url: '2TLR4xNAsSg'
              },
              {
                title: 'Çok Yalnızım',
                url: 'bOB6RcfuC7I'
              },
              {
                title: ' Yokluğuna Böyle Alışıyorum',
                url: '9HWPd7RGX9E'
              },
              {
                title: 'Gülşen-i Hüsnüne',
                url: 'lLug4xUttac'
              },
              {
                title: 'Yalan Dünya',
                url: 'WCzllnnt5Ck'
              },
              {
                title: 'Bir Darıldık Bir Barıştık',
                url: 'c4jBMxxXUS8'
              },
              {
                title: 'Sana Aşık Oluyorum',
                url: 'NC7oPxV-nk4'
              },
              {
                title: 'Kusura Bakma',
                url: 'BAPk5zOgpJM'
              },
              {
                title: 'Nasıl İhanet Ettin',
                url: 'kQwfcdsP5L0'
              },
              {
                title: 'Göze mi Geldik',
                url: 'JgRXFsdvFnM'
              },
              {
                title: 'Unutmak istiyorum',
                url: 'N8ayc3rIIeQ'
              },
              
              // diğer videolar
            ],
            selectedSongs: {
                title: 'Hatırlar mısın',
                url: '9E-E--KgAZs'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
    
    
      </style>
    
