<template>
    <div class="footer">
        <div class="footer-order">
            <div class="footer_contents">
            <div class="name">HÜLYA AVŞAR</div>
            <div class="social">
                <ul>
                    <li><a href="https://www.facebook.com/HulyaAvsarOfficial/?locale=tr_TR" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/facebook.png"></a></li>
                    <li><a href="https://twitter.com/hulyavsar" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/twitter.png"></a></li>
                    <li><a href="https://www.instagram.com/hulyavsr/?hl=tr" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/instagram.png"></a></li>
                    <li><a href="https://www.youtube.com/channel/UCxht1rlSogxpkR5sDXMwFWQ" target="_blank"><img style="width: 35px; height: 30px; background: red;border-radius: 9px;" src="../assets/image/social/youtube.png"></a></li>
                    <li><a href="https://open.spotify.com/artist/5ZQ2xzMSMon0UUnulwsPiO" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/spotify.png"></a></li>
                </ul>
            </div>
            <div class="hr"><hr></div>
            <div class="text">
                <P>©2023. | Hülya Avşar  Fan Sayfası |  </P>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'indexFooter',
    }
</script>

<style>

</style>