import Vue from 'vue'
import App from './App.vue'
import {router} from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.config.productionTip = false

import './assets/scss/app.scss';

new Vue({
  render: h => h(App),
  router,
  history,
}).$mount('#app')
