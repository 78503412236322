<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <h4 >{{ selectedSongs.title }}</h4>
                            </div>
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'pagesFilmler',
        data() {
          return {
            videoData: [
              {
                title: 'Hülya',
                url: 'Ab9jlOG8IR4'
              },
              {
                title: 'Berlin in Berlin',
                url: 'Nk1aUjPwjz4'
              },
              {
                title: 'Haram',
                url: '48kb7gbLwGk'
              },
              {
                title: 'Tutku',
                url: '2YuYEf_iuNE'
              },
              {
                title: 'Mavi Mavi',
                url: 'koXyly5qqGw'
              },
              {
                title: 'Bir Kırık Bebek',
                url: 'Bl5KbtPkPy8'
              },
              {
                title: 'Sekreter',
                url: 'JGKV71yBLrI'
              },
              {
                title: 'Yabancı',
                url: '5fa5LU7s8rI'
              },
              {
                title: 'Hasan Boğuldu',
                url: 'W0g7LsUjZ_Q'
              },
              {
                title: "Fatma Gül'ün Suçu Ne",
                url: 'OOhdnfJDmSI'
              },
              {
                title: "Ziyaret",
                url: '-dCnwqK-ZzA'
              },
              {
                title: "Nefret",
                url: 'un9vkzPVUDI'
              },
              {
                title: "Ayşem",
                url: 'Q6wJK0a-hy4'
              },
              {
                title: "Mavi Melek",
                url: 'Rfr7JNVhItE'
              },
              {
                title: "Benim Sinemalarım",
                url: 'ME4NTLo17DA'
              },
              {
                title: "Çil Horoz",
                url: '5W1q4qjUxoM'
              },
              {
                title: "Dağlı Güvercin",
                url: 'HQfnbcwo_JQ'
              },
              {
                title: "Alın Yazım",
                url: 'X0B4vN5kvus'
              },
              {
                title: "İki Genç Kız",
                url: 'ARIAV6UzqqE'
              },
              {
                title: "Geri Dön",
                url: 'C5-4tmYdqb8'
              },
              {
                title: "Aşksın",
                url: 'Yj1Rb16qKC0'
              },
              {
                title: "Selfie",
                url: '-eJRm7vym3I'
              },
              {
                title: "72. Koğuş",
                url: 'LFZesm5hjDw'
              },
              {
                title: "Geri Dön",
                url: 'C5-4tmYdqb8'
              },
              {
                title: "Ömrümün Tek Gecesi",
                url: 'LyuWoE28h9M'
              },
              {
                title: "Ölüm Yolu",
                url: '3QutgGem9j0'
              },
              {
                title: "Güneş Doğarken",
                url: 'QtIcJP4wX2s'
              },
              {
                title: "Tele Kızlar",
                url: '10wVNXMheaI'
              },
              {
                title: "Uzun Bir Gece",
                url: 'yYNphDMTZlQ'
              },
              {
                title: "Fotoğraflar",
                url: 'zbg0BprLKec'
              },
              {
                title: "Hababam Sınıfı Askerde",
                url: 'paJSYtOXXXs'
              },
              {
                title: "Fazilet",
                url: 'LeZ4vI8Y2eI'
              },
              {
                title: "Karanfilli Naciye",
                url: 'bZKydd8stEw'
              },
              {
                title: "Çelik Mezar",
                url: 'am2ITq0VvcI'
              },
              {
                title: "Salkım Hanımın Taneleri",
                url: 'dQLNcdnq0hk'
              },
              {
                title: "Bir Kadının Anatomisi",
                url: '_xTFaNhfZJQ'
              },
              {
                title: "Kaptan",
                url: '7oZppxOPdvo'
              },
              {
                title: "Paranın Esiri",
                url: 'DwXYJTRM6to'
              },
              {
                title: "Sevda Ateşi",
                url: 'HVoiy_OSXNE'
              },
              {
                title: "Aşk Hikayemiz",
                url: 'a3bEcJ1XEYE'
              },
              {
                title: "Suçlu Gençlik",
                url: 'DsM-2vqGHDs'
              },
              {
                title: "Yarın Yarın",
                url: 'm1UyMopKDOU'
              },
              {
                title: "Kahır",
                url: 'doOIynY1GKg'
              },
              {
                title: "Kısrak",
                url: 'yyBhHmK_sWA'
              },
              {
                title: "Öğretmen Zeynep",
                url: 'oO5TotzrxS8'
              },
              {
                title: "Yeşil Işık",
                url: 'v538fttuhUE'
              },
              {
                title: "Almancının Karısı",
                url: 'VxkhskQoQxk'
              },
              
              // diğer videolar
            ],
            selectedSongs: {
                title: 'Hülya',
                url: 'Ab9jlOG8IR4'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
        .movie .movcontainer .movies-name ul {
            overflow-x: scroll !important;
            justify-content: space-between;
        }
    
      </style>
    