<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <p >Hülya Avşar, sadece bir el kamerasıyla çektiği, yapımcılığını, yönetmenliğini, senaristliğini, oyunculuğunu kendi yaptığı otobiyografik filmi Selfi'</p>
                            </div>
                            
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'pagesSelfie',
        data() {
          return {
            videoData: [
              {
                title: 'Bölüm 1',
                url: 'TWUOKlNAMos'
              },
              {
                title: 'Bölüm 2',
                url: 've2ZYdcyBrM'
              },
              {
                title: 'Bölüm 3',
                url: '7Zz5G0fTE9k'
              },
              {
                title: 'Bölüm 4',
                url: 'm7-w0Hg-jso'
              },
              {
                title: 'Bölüm 5',
                url: 'oCKphitaNwM'
              },
              {
                title: 'Bölüm 6',
                url: 'YmXlJ9RLUL0'
              },
              {
                title: 'Bölüm 7',
                url: 'WCUAZ20L50E'
              },
              {
                title: 'Bölüm 8(Fİnal)',
                url: 'ZFuqkGsmyFA'
              },
             
              // diğer videolar
            ],
            selectedSongs: {
              title: 'Bölüm 1',
                url: 'TWUOKlNAMos'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
    .movies-player-video-title p{
        color: white;
    }

    .movies-player-video-title{
        height: 10% !important;
    }
    .movie .movcontainer .movies-player-video iframe {
    height: 90% !important;
    width: 100%;
}
@media only screen and (max-width: 900px) {
    .movie .movcontainer .movies-player-video-title {
        display: none;
    }
    .movie .movcontainer .movies-player-video {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

}
    
      </style>
    