<template>
    <div class="container">
       <div class="incontainer">
        <div class="biyografi">
            <div class="biyografi-text">
                <div class="title">
                    <h1>Hülya Avşar</h1>
                </div>
                <div class="textp">
                    <p>Hülya Avşar (Doğum 10 Ekim 1963, Balıkesir), Kürt asıllı Türk sinema ve dizi oyuncusu, şarkıcı ve sunucu.</p>
                </div>
            </div>
            <div class="biyografi-img">
                <img  src="../../assets/image/gallery/26.jpg" alt="">
            </div>
        </div>
       </div>
    </div>
</template>,
<script>
export default{
    name:"PageBiyografi"
}
</script>
<style>
</style>