<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <h4 >{{ selectedSongs.title }}</h4>
                            </div>
                            
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'albumDuydunmu',
        data() {
          return {
            videoData: [
              {
                title: 'Duydun mu?',
                url: 'RNl99FHkKng'
              },
             
              // diğer videolar
            ],
            selectedSongs: {
                title: 'Duydun mu?',
                url: 'RNl99FHkKng'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
    
    
      </style>
    