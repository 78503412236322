<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <h4 >{{ selectedSongs.title }}</h4>
                            </div>
                            
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'albumHulyaGibi',
        data() {
          return {
            videoData: [
              {
                title: 'Hülya Gibi',
                url: ' o7moWVVjls8'
              },
              {
                title: 'Gel Eskiye Dönelim',
                url: '_DJFp1xImg4'
              },
              {
                title: 'Ayrılık',
                url: 'RadPAl48wEY'
              },
              {
                title: 'Yüreğimde Bir Şey Var',
                url: 'NkEAP17LThg'
              },
              {
                title: 'Ne Günlere Kaldık',
                url: '_FSumLtJDms'
              },
              {
                title: 'Kaşın Gözün Şöyle Dursun',
                url: '1Fvn8k6zcno'
              },
              {
                title: 'Gel Gir Tekrar Sineme',
                url: 'XBoVbPeY5LM'
              },
              {
                title: 'Zamana Bırak',
                url: 'V1WFRh6Hg-Y'
              },
              {
                title: 'Nasılsın Bugün',
                url: 'IHcflatRjwk'
              },
              {
                title: 'Unutamazdın',
                url: 'KvbgyNLmrVM'
              },
              {
                title: 'Sarsam Diyorum',
                url: 'nyx_FuVWJiQ'
              },
              {
                title: 'Hata',
                url: 'UMrhWf_Yy58'
              },
              {
                title: 'Agora Meyhanesi',
                url: 'rIBPw1Tbna4'
              },
              
              // diğer videolar
            ],
            selectedSongs: {
                title: 'Hülya Gibi',
                url: ' PgflC4pnVOs'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
    
    
      </style>
    
