<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <h4 >{{ selectedSongs.title }}</h4>
                            </div>
                            
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'albumHersey',
        data() {
          return {
            videoData: [
              {
                title: 'Ben Aşkı Ölümsüz Bilenlerdenim ',
                url: 'i7OSLVr_MsI'
              },
              {
                title: 'Kalplerimiz Bir Olsa',
                url: 'RX5B2icyPz8'
              },
              {
                title: 'Bir Demet Yasemen',
                url: '2XJMHT93A6A'
              },
              {
                title: 'Aşkımız Dillerde',
                url: 'LH5bThrguFM'
              },
              {
                title: 'Ağlamışım Gülmüşüm',
                url: '3IWydpQZJ3g'
              },
              {
                title: 'Aylara Sor Yıllara Sor',
                url: '5-OW7QioADE'
              },
              {
                title: 'Koş',
                url: 'lYSy3ciiUDk'
              },
              {
                title: 'Her Şey Gönlünce Olsun',
                url: '4VB_hEe0yFw'
              },
              {
                title: 'Sen de Ah Sen de',
                url: '7zU9ekif4vI'
              },
              {
                title: 'Ya Beni',
                url: 'jxTbErz8n7U'
              },
              {
                title: 'Bil Yeter Ki',
                url: 'A2IMc5YxOxM'
              },

              
              // diğer videolar
            ],
            selectedSongs: {
                title: 'Ben Aşkı Ölümsüz Bilenlerdenim ',
                url: 'i7OSLVr_MsI'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
    
    
      </style>
    
