<template>
    <div class="container">
        <div class="incontainer">
            <div class="diskografi">
                <div class="diskografi-albums">
                    <div v-for="album in albums" :key="album.id">
                        <router-link
                        :to="album.path"
                        class="album"  
                        >
                            <img class="album-img" :src="album.imgsrc" alt="" >
                            <figcaption><p>{{ album.id }}</p></figcaption>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default{
    name:"PageDiskografi",
        data(){
           
            return{
                albums:[
                {id: "Aşk Büyükse" ,                imgsrc:require('@/assets/image/albums/askbuyukse.jpg'),      path:{ name: 'ask' } },
                {id: "Aşıklar Delidir" ,            imgsrc:require('@/assets/image/albums/asıklardelidir.jpg'),  path:{ name: 'asiklar' }},
                {id: "Hayat Böyle" ,                imgsrc:require('@/assets/image/albums/hayatböyle.jpg'),      path:{ name: 'hayat' }},
                {id: "Yarası Saklım" ,              imgsrc:require('@/assets/image/albums/yarasisaklim.jpg'),    path:{ name: 'Yarasisaklim' }},
                {id: "Dost musun ? Düşman mısın?" , imgsrc:require('@/assets/image/albums/dostdusman.jpg'),      path:{ name: 'Dostdusman' }},
                {id: "Hülya Gibi" ,                 imgsrc:require('@/assets/image/albums/hulyagibi.jpg'),       path:{ name: 'Hulyagibi' }},
                {id: "Hatırlar mısın?" ,            imgsrc:require('@/assets/image/albums/hatirlarmisin.jpg'),   path:{ name: 'Hatirlarmisin' }},
                {id: "Herşey Gönlünce Olsun" ,      imgsrc:require('@/assets/image/albums/hersey.jpg'),          path:{ name: 'Hersey' }},
                {id: "Anlatma" ,                    imgsrc:require('@/assets/image/albums/hulya-avsar-2.webp'),  path:{ name: 'Anlatma' }},
                {id: "Yapma Aşkım" ,                imgsrc:require('@/assets/image/albums/yapmaaskim1.jpg'),     path:{ name: 'Yapma' }},
                {id: "Sevmek,Gülmek, Mutlu Olmak İbadettir" ,imgsrc:require('@/assets/image/albums/sevmek.jpg'), path:{ name: 'Sevmek' }},
                {id: "Duydun mu?" ,                 imgsrc:require('@/assets/image/albums/duydunmu.jpg'),        path:{ name: 'Duydunmu' }},
                {id: "Sen aşk mısın?" ,             imgsrc:require('@/assets/image/albums/senaskmisin.jpg'),     path:{ name: 'Senaskmisin' }},
                {id: "Saymadım Kaç Yıl Oldu" ,      imgsrc:require('@/assets/image/albums/saymadim.jpg'),        path:{ name: 'Saymadim' }},
                {id: "Geçmiş Olsun" ,               imgsrc:require('@/assets/image/albums/gecmisolsun.jpg'),     path:{ name: 'Gecmis' }},
                {id: "Sevdim" ,                     imgsrc:require('@/assets/image/albums/sevdim.jpg'),          path:{ name: 'Sevdim' }},
                ],
            }
        }
}
</script>
<style>

</style>