import Vue from "vue"
import VueRouter from "vue-router";
import Home from "../components/pages/Home.vue"
import Biyografi from "../components/pages/Biyografi.vue"
import Galeri from "../components/pages/Galeri.vue"
import Diskografi from "../components/pages/Diskografi.vue"
import Filmler from "../components/pages/Filmler.vue"
import Selfie from "../components/pages/Selfie.vue"
                /* Albums   :to="{ name: ""}" */
import Askbuyuk from "../components/pages/albums/Askbuyuk.vue"
import Asiklar from "../components/pages/albums/Asiklardelidir.vue"
import Hayat from "../components/pages/albums/Hayatboyle.vue"
import Yarasisaklim from "../components/pages/albums/Yarasisaklim.vue"
import Dostdusman from "../components/pages/albums/Dostdusman.vue"
import Duydunmu from "../components/pages/albums/Duydunmu.vue"
import Gecmis from "../components/pages/albums/Gecmis.vue"
import Hatirlarmisin from "../components/pages/albums/Hatirlarmisin.vue"
import Anlatma from "../components/pages/albums/Anlatma.vue"
import Hersey from "../components/pages/albums/Hersey.vue"
import Hulyagibi from "../components/pages/albums/Hulyagibi.vue"
import Saymadim from "../components/pages/albums/Saymadim.vue"
import Senaskmisin from "../components/pages/albums/Senaskmisin.vue"
import Sevdim from "../components/pages/albums/Sevdim.vue"
import Sevmek from "../components/pages/albums/Sevmek.vue"
import Yapma from "../components/pages/albums/Yapma.vue"




Vue.use(VueRouter)

export const routes =[
    {path:"/" , name:"Home" , component: Home },
    {path:"/Biyografi" , name:"Biyografi" , component: Biyografi },
    {path:"/Diskografi" , name:"Diskografi" , component: Diskografi },
    {path:"/Filmler" , name:"Filmler" , component: Filmler },
    {path:"/Galeri" , name:"Galeri" , component: Galeri },
    {path:"/Selfie" , name:"Selfie" , component: Selfie },
    {path :"*" , redict:"/" , component : Home },

    //Albums Conponent//
    {path:"/asklar-büyükse" , name:"ask" , component: Askbuyuk },
    {path:"/asiklar" , name:"asiklar" , component: Asiklar },
    {path:"/hayat-böyle" , name:"hayat" , component: Hayat },
    {path:"/Yarasisaklim" , name:"Yarasisaklim" , component: Yarasisaklim },
    {path:"/Dostdusman" , name:"Dostdusman" , component: Dostdusman },
    {path:"/Hulyagibi" , name:"Hulyagibi" , component: Hulyagibi },
    {path:"/Hatirlarmisin" , name:"Hatirlarmisin" , component: Hatirlarmisin },
    {path:"/Hersey" , name:"Hersey" , component: Hersey },
    {path:"/Duydunmu" , name:"Duydunmu" , component: Duydunmu },
    {path:"/Gecmis" , name:"Gecmis" , component: Gecmis },
    {path:"/Anlatma" , name:"Anlatma" , component: Anlatma },
    {path:"/Saymadim" , name:"Saymadim" , component: Saymadim },
    {path:"/Senaskmisin" , name:"Senaskmisin" , component: Senaskmisin },
    {path:"/Sevdim" , name:"Sevdim" , component: Sevdim },
    {path:"/Sevmek" , name:"Sevmek" , component: Sevmek },
    {path:"/Yapma" , name:"Yapma" , component: Yapma },

];


export const router = new VueRouter({
    mode: "history",
    routes,
  });

