<template>
     <div class="header">
      <div class="header-mobil" >
        <div class="logo">
          <svg @click="responsive =!responsive" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none"> <path d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z" fill="#000000"/> <path d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z" fill="#000000"/> <path d="M3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H3Z" fill="#000000"/> </svg>
          <p>Hülya Avşar</p>
        </div>
        <div class="titles" v-if="responsive">
          <ul @click="responsive =!responsive">
            <router-link
            active-class="active" 
            to="/home"
            exact
            tag="li">
            <a>ANASAYFA</a>
            </router-link>
            <router-link
            active-class="active"  
            to="/Biyografi"
            exact
            tag="li">
            <a>BİYOGRAFİ</a>
            </router-link>
            <router-link 
            active-class="active" 
            to="/Diskografi"
            exact
            tag="li">
            <a>DİSKOGRAFİ</a>
            </router-link>
            <router-link 
            active-class="active" 
            to="/Filmler"
            exact
            tag="li">
            <a>FİLMLER</a>
            </router-link>
            <router-link 
            active-class="active" 
            to="/Galeri"
            exact
            tag="li">
            <a>GALERİ</a>
            </router-link>
            <router-link
            active-class="active"  
            to="/Selfie"
            exact
            tag="li">
            <a>SELFİE</a>
            </router-link>
          </ul>
        </div>
      </div>
      <div class="web">
        <div class="web-header">
        <div class="logo">
          <h1 href="">Hülya Avşar</h1>
        </div>
        <div class="titles">
          <ul>
            <router-link
            active-class="active" 
            to="/home"
            exact
            tag="li">
            <a>ANASAYFA</a>
            </router-link>
            <router-link
            active-class="active"  
            to="/Biyografi"
            exact
            tag="li">
            <a>BİYOGRAFİ</a>
            </router-link>
            <router-link 
            active-class="active" 
            to="/Diskografi"
            exact
            tag="li">
            <a>DİSKOGRAFİ</a>
            </router-link>
            <router-link 
            active-class="active" 
            to="/Filmler"
            exact
            tag="li">
            <a>FİLMLER</a>
            </router-link>
            <router-link 
            active-class="active" 
            to="/Galeri"
            exact
            tag="li">
            <a>GALERİ</a>
            </router-link>
            <router-link
            active-class="active"  
            to="/Selfie"
            exact
            tag="li">
            <a>SELFİE</a>
            </router-link>
          </ul>
        </div>
        <div class="social-media">
          <ul>
            <li><a href="https://www.facebook.com/HulyaAvsarOfficial/?locale=tr_TR" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/facebook.png"></a></li>
            <li><a href="https://twitter.com/hulyavsar" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/twitter.png"></a></li>
            <li><a href="https://www.instagram.com/hulyavsr/?hl=tr" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/instagram.png"></a></li>
            <li><a href="https://www.youtube.com/channel/UCxht1rlSogxpkR5sDXMwFWQ" target="_blank"><img style="width: 35px; height: 30px; background: red;border-radius: 9px;" src="../assets/image/social/youtube.png"></a></li>
            <li><a href="https://open.spotify.com/artist/5ZQ2xzMSMon0UUnulwsPiO" target="_blank"><img style="width: 30px; height: 30px;" src="../assets/image/social/spotify.png"></a></li>
          </ul>
        </div>
      </div>
      </div>

     </div>
</template>

<script>
export default {
  name: 'FirstHeader',
  props: {
    msg: String
  },
  data(){
    return {
      responsive:""
    }
  }
}
</script>

<style >

</style>
