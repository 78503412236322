<template>
    <div class="container">
        <div class="incontainer">
            <div class="movie">
            <div class="movcontainer">
                <div class="movies">
                    <div class="movies-name">
                        <ul>
                            <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                               <a> {{ sound.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="movies-player" >
                        <div class="movies-player-video" v-if="selectedSongs">
                            <div class="movies-player-video-title">
                                <h4 >{{ selectedSongs.title }}</h4>
                            </div>
                            
                            <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
      </template>
      
      <script>
    
      export default {
        name:'albumHayatboyle',
        data() {
          return {
            videoData: [
              {
                title: 'Aradın Mı',
                url: 'upjkTdCgonY'
              },
              {
                title: 'Senden Sonra',
                url: 'Mf_L4SfDdEc'
              },
              {
                title: 'İki Dirhem Bir Çekirdek',
                url: 'D0wAP7Rzd-0'
              },
              {
                title: 'Hep Paramparça',
                url: 'sVNr39f5hAA'
              },
              {
                title: 'Başına Buyruk',
                url: 'UHfz5HQLsKU'
              },
              {
                title: 'Birileri Senden Söz Etti',
                url: 'MhrsbkLEOvg'
              },
              {
                title: 'Aşkın İbadet',
                url: 'oHYr2l-1H1c'
              },
              {
                title: 'Ah Be Güzelim',
                url: 'TxjclIBno58'
              },
              {
                title: 'Hayat Böyle',
                url: 'px9tklBDBFM'
              },
              {
                title: 'Sevda',
                url: 'tIuA9j10P4w'
              },
              
              // diğer videolar
            ],
            selectedSongs: {
                title: 'Aradın Mı',
                url: 'upjkTdCgonY'
          }
            
          };
        },
        methods:{
            openSound(index) {
                this.selectedSongs = this.videoData[index];
                console.log(this.selectedSongs.url)
            }
        }
      };
    
    
      </script>
      
      <style scoped>
    
    
      </style>
    
