<template>
    <div class="container">
        <div class="incontainer">
            <div class="home">
                <div class="iframe">
                    <iframe  src="https://www.youtube.com/embed/1ehXGkvqa0w" title="Hülya Avşar - Anlatma (Official Video)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>,
<script>
export default{
    name:"PageHome"
}
</script>
<style>

</style>