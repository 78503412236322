<template>
<div class="container">
    <div class="incontainer">
        <div class="movie">
        <div class="movcontainer">
            <div class="movies">
                <div class="movies-name">
                    <ul>
                        <li :class="{ active : selectedSongs.url == sound.url}"  v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                           <a> {{ sound.title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="movies-player" >
                    <div class="movies-player-video" v-if="selectedSongs">
                        <div class="movies-player-video-title">
                            <h4 >{{ selectedSongs.title }}</h4>
                        </div>
                        
                        <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
  </template>
  
  <script>

  export default {
    name:'albumAskbuyuk',
    data() {
      return {
        videoData: [
          {
            title: 'Yüreğimin Elleri',
            url: 'UKNzJb8IpNU'
          },
          {
            title: 'A Benim Akılsızım',
            url: 'qqYcnNIArTU'
          },
          {
            title: 'Aşk İçin Doğulur',
            url: 'vJSO81WViV4'
          },
          {
            title: 'Bugün Sözüm Olsun',
            url: 'Vwv_0IuW05g'
          },
          {
            title: 'Bari Geçerken Uğra',
            url: 'aaXpG5YDYbY'
          },
          {
            title: 'Bana Bir Koca Lazım',
            url: '7OE6NJa4_h8'
          },
          {
            title: 'Sana Sakladım',
            url: 'gnEuEu6S9pk'
          },
          {
            title: 'Boşver',
            url: 'Ufs4Ay4ck8w'
          },
          {
            title: 'O Adama',
            url: 'QQFp5LX_mEc'
          },
          {
            title: 'Bana Bir Koca Lazım (Altay Ekren Remix)',
            url: 'hJsKZFth0W0'
          },
          
          // diğer videolar
        ],
        selectedSongs: {
            title: 'Yüreğimin Elleri',
            url: 'UKNzJb8IpNU'
      }
        
      };
    },
    methods:{
        openSound(index) {
            this.selectedSongs = this.videoData[index];
            console.log(this.selectedSongs.url)
        }
    }
  };


  </script>
  
  <style scoped>


  </style>
