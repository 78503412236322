<template>
    <div class="container">
        <div class="incontainer">
            <div class="gallery">
                <div class="gallery-grid">
                    <div class="grid-item " v-for="image in images" :key="image.id">
                            <img :src="image.imagePath" class="grid-item-image" @click="openImage(image)">
                    </div>
                </div>
                <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="true">
                    <img :src="selectedImage.imagePath" class="dialog-image">
                </el-dialog>
            </div>
        </div>
    </div>
</template>,
<script>
export default {
  name:"PageGaleri" ,
  data() {
    return {
      images: [
        { id: 1, imagePath: require('@/assets/image/gallery/1.jpg') },
        { id: 2,  imagePath: require('@/assets/image/gallery/15.jpg') },
        { id: 3,  imagePath: require('@/assets/image/gallery/3.jpg') },
        { id: 4,  imagePath: require('@/assets/image/gallery/4.jpg') },
        { id: 5,  imagePath: require('@/assets/image/gallery/16.jpg') },
        { id: 6,  imagePath: require('@/assets/image/gallery/6.jpg') },
        { id: 7,  imagePath: require('@/assets/image/gallery/7.jpg') },
        { id: 8,  imagePath: require('@/assets/image/gallery/8.jpg') },
        { id: 9,  imagePath: require('@/assets/image/gallery/9.jpg') },
        { id: 10,  imagePath: require('@/assets/image/gallery/13.jpg') },
        { id: 11,  imagePath: require('@/assets/image/gallery/18.jpg') },
        { id: 12,  imagePath: require('@/assets/image/gallery/17.jpg') },
        // Diğer resimler...
      ],
      dialogVisible: false,
      selectedImage: ""
    };
  },
  mounted() {
  console.log(this.images);
},
  methods: {
    openImage(image) {
      this.selectedImage = image;
      this.dialogVisible = true;
    }
  }
};
</script>

