import { render, staticRenderFns } from "./Hatirlarmisin.vue?vue&type=template&id=3c16807e&scoped=true&"
import script from "./Hatirlarmisin.vue?vue&type=script&lang=js&"
export * from "./Hatirlarmisin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c16807e",
  null
  
)

export default component.exports